import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { withFirebase } from '../Firebase';
import { AppBar, Toolbar, Typography, Button, withStyles, MenuItem, IconButton, Hidden } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { compose } from 'redux';


const styles = theme => ({
  toolbarTitle: {
    flex: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
});


class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  handleDrawerToggle = () => {
    const { firebase } = this.props;
    firebase.toogleDrawer()
  }

  completeUser(){
    const { authUser } = this.props;
    return authUser
  }

  render() {
    const { authUser } = this.props;

    const { classes } = this.props;

    return (

      <AppBar
        position="fixed"
        color='secondary'
        className={classes.appBar}
      >
        <Toolbar
        >
          {this.completeUser() ? (
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={event => this.handleDrawerToggle()}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          ) : (null)}
          <a href={ROUTES.DASHBOARD}><img src="/ic_logo.png" height="47" width="160" /></a>
          <div className={classes.grow} />
          {this.completeUser() ? (
            <NavigationAuth authUser={authUser} props={this.props} />
          ) : (
              <NavigationNonAuth />
            )}
        </Toolbar>
      </AppBar>

    );
  }
}


const NavigationAuth = ({ authUser, props }) => (
  <div>

    <div className={props.classes.sectionDesktop}>
      <Button color="inherit"href={ROUTES.DASHBOARD}>Dashboard</Button>
      <Button color="inherit" onClick={props.firebase.doSignOut}>Sign out</Button>
    </div>
    <div className={props.classes.sectionMobile}>
      <IconButton
        id='dashboardMobile'
        href={ROUTES.DASHBOARD}
        color="inherit"
        >
        <DashboardIcon/>
      </IconButton>
      <IconButton
        onClick={props.firebase.doSignOut}
        color="inherit"
        >
        <LogoutIcon />
      </IconButton>

    </div>
  </div>
);

const NavigationNonAuth = () => (
  <div>

    <Button color="inherit" href={ROUTES.LOGIN}>Login</Button>
    {/* <Button color="inherit" href={ROUTES.REGISTER}>Registrieren</Button> */}

  </div>

);

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

export default compose(
  withFirebase,
  connect(mapStateToProps),
  withStyles(styles)
)(Navigation);

