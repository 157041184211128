import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { withStyles } from '@material-ui/core';
import { CssBaseline, Grid, Paper, Drawer, List, ListItemIcon, ListItemText, Divider, ListItem, TextField, FormControl, Checkbox, Typography, Button, MenuItem, FormControlLabel, Link } from '@material-ui/core';



const styles = theme => ({
  layout: {
    width: 'auto',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar
});



class PasswordForget extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.location.search);
    const email = params.get('email');

    this.state = { 
      email: email
     };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ done: true });
      })
      .catch(error => {
        console.log("error = ", error);
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error, done } = this.state;
    const { classes } = this.props;

    const isInvalid = email ? email === '' : true;

    return (
      <main className={classes.layout}>
        <div className={classes.toolbar} />


        <Paper elevation={2} className={classes.paper}>
          <React.Fragment>
            <React.Fragment>

              <Grid container spacing={3}>


              <Grid item xs={12}>
              <Typography component="h1" variant="h5" color="textSecondary">Forgot password</Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    value={this.state.email}
                    label="Company email address"
                    onChange={this.onChange}
                    fullWidth
                    autoComplete="email"
                    variant="outlined"
                  />
                </Grid>

              </Grid>
            </React.Fragment>

            {done && <Typography component="h1" variant="h5" color="textSecondary"><p>Please check the email above for a link to reset your password</p></Typography>}

            <div className={classes.buttons}>

              <Button disabled={isInvalid || done}
                id="btn-signon"
                variant="contained"
                color="primary"
                onClick={this.onSubmit}
                className={classes.button}
              >
                Reset password
            </Button>
            </div>
          </React.Fragment>

        </Paper>

      </main>
      
    );
  }
}



export default compose(
  withFirebase,
  withStyles(styles)
)(PasswordForget);

