import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';

import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';

import { CssBaseline, Grid, Paper, withStyles, Drawer, List, ListItemIcon, ListItemText, Divider, ListItem, Typography, Button, Tabs, Tab } from '@material-ui/core';
import withBrowserDetection from '../Session/withBrowserDetection';
import DhProgressTable from '../Dashboard/DhProgressTable';
import withAdmin from '../Session/withAdmin';
import moment from 'moment';
import AdminTabs from './AdminTabs';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    margin: `${theme.spacing(2)}px 0px`,
    padding: `${theme.spacing(2)}px`,
  },
  button: {
    marginTop: theme.spacing(1)
  },
  toolbar: theme.mixins.toolbar
});

class AdminUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }



  componentDidMount() {
    this.loadData()
  }

  componentWillUnmount() {

  }

  loadData = () => {
    const { firebase } = this.props;

    // firebase
    //   .userList()
    //   .then(users => {
    //     var userIds = users.map(user => (user.id))
    //     this.setState({ userIds: userIds });
    //     // this.loadProgress(userIds)

    //   }, function (error) {
    //     console.log("users load", error);
    //   });

    firebase
      .userCertificateDoneObject()
      .then(users => {
        console.log("users", users);
        var userIds = users.map(user => (user.id))
        this.setState({ userIds: userIds });

      }, function (error) {
        console.log("users load", error);
      });

  }

  async loadProgress(userIds){
    const { firebase } = this.props;
    var videoProgressCount = {}
    var exerciseProgressCount = {}
    for(var userId of userIds){
      var prog = await firebase.userProgressObject(userId);
      // console.log("prog = ",userId, prog);
      if(prog){
        var keys = Object.keys(prog)
        if(keys){
          for(var key of keys){
            if(prog[key] && prog[key].videosDone){
              var size = prog[key].videosDone.length
              // console.log("length = ",userId, key, size);
              if(size != 0){
                videoProgressCount[key] = (videoProgressCount[key] ? videoProgressCount[key] : 0) + size
              }
            }
            if(prog[key] && prog[key].exerciseDone){
              var done = prog[key].exerciseDone
              // console.log("length = ",userId, key, size);
              if(done){
                exerciseProgressCount[key] = (exerciseProgressCount[key] ? exerciseProgressCount[key] : 0) + 1
              }
            }
          }
        }
      }
    }
    console.log("videoProgressCount = ",videoProgressCount);
    console.log("exerciseProgressCount = ",exerciseProgressCount);
 
  }


  render() {
    const { classes } = this.props;
    const { authUser } = this.props;
    const { userId } = this.state;
    const { userIds } = this.state;

    console.log("authUser = ", authUser);
    console.log("userId = ", userId);
    console.log("userIds = ", userIds);

    return (
      <div className={classes.root} >
        <div className={classes.toolbar} />

          <AdminTabs />

          <Grid container className={classes.grid} xs={12} sm={12} md={12} spacing={3} >

            {userIds && userIds.map(otherId => (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <DhProgressTable
                    userId={otherId} 
                    currUserId={authUser.uid}
                    isOther={true}
                    />
              </Grid>
            ))} 

          </Grid>
      </div>

    );
  }
}



const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withBrowserDetection,
  withAuthorization(condition),
  withAdmin,
  withStyles(styles)
)(AdminUsers);
