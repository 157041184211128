import { FormControl, FormControlLabel, Radio, RadioGroup, Card, CardActionArea, CardContent, Paper, Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import Plyr from 'react-plyr';
import Player from './Player';



const styles = theme => ({
  paper: {
    padding: theme.spacing(2)
  },
  paperPlayer: {

  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },

});


class Video extends Component {
  constructor(props) {
    super(props);
    this.onClickContinue = this.onClickContinue.bind(this);

    this.state = {
      loading: true,
    };

  }

  onClickContinue() {
    const { video } = this.props;
    this.props.onVideoDone(video.id);
  }

  render() {
    const { classes } = this.props;
    const { video } = this.props;
    const { done } = this.props;
    const { progress } = this.props;
    const { platformUrl } = this.props;
    const { defaultPlatform } = this.props;
    const { currIdx } = this.props;
    const { courseId } = this.props;
    const { courseUrlName } = this.props;


    return (
      <div>

        <Typography variant="h5" className={classes.title}>
          {video.title}
        </Typography>

        <Player
            video={video}
            progress={progress}
            platformUrl={platformUrl}
            defaultPlatform={defaultPlatform}
            showBottomControls={true}
            currIdx={currIdx}
            courseId={courseId}
            courseUrlName={courseUrlName}
            done={done}
            onVideoDone={this.onClickContinue} />

      </div>

    );

  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
  withStyles(styles)
)(Video);