import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import * as serviceWorker from './serviceWorker';

import store from './store';
import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
  <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </Provider>
  </MuiPickersUtilsProvider>,
  document.getElementById('root'),
);

// drop logs in production
if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
