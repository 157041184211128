import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';

import { CssBaseline, Grid, Paper, withStyles, Drawer, List, ListItemIcon, ListItemText, Divider, ListItem, Typography, Stepper, Step, StepButton, Hidden, CircularProgress, Button, StepLabel } from '@material-ui/core';
import Navigation from '../Navigation';
import UserInfo from '../UserInfo';
import Question from './Question';
import Video from './Video';
import Exercise from './Exercise';
import * as ROUTES from '../../constants/routes';
import Explanation from './Explanation';
import PlatformSelect from './PlatformSelect';
import PlatformInfo from './PlatformInfo';
import withBrowserDetection from '../Session/withBrowserDetection';
import moment from 'moment';
import PrePage from './PrePage';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    color: theme.palette.text.secondary,
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  grid: {
    margin: `${theme.spacing(2)}px 0px`,
    padding: `${theme.spacing(2)}px`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 230,
    },
  },
  toolbar: theme.mixins.toolbar
});

class CoursePage extends Component {
  constructor(props) {
    super(props);
    this.handleQuestionSelected = this.handleQuestionSelected.bind(this);
    this.goToVideo = this.goToVideo.bind(this);
    this.handleVideoDone = this.handleVideoDone.bind(this);
    this.handleExplanationDone = this.handleExplanationDone.bind(this);
    this.handlePlatformInfoDone = this.handlePlatformInfoDone.bind(this);
    this.handlePrePageDone = this.handlePrePageDone.bind(this);
    this.handleVideoPlatformSelected = this.handleVideoPlatformSelected.bind(this);
    this.shuffle = this.shuffle.bind(this);
    this.hasMultiplePlatforms = this.hasMultiplePlatforms.bind(this);

    this.state = {
      loading: true,
      showQuestion: true,
      setStartTimeDone: false,
    };
  }

  componentDidMount() {
    this.loadCourse()
  }

  componentWillUnmount() {

  }

  loadCourse = () => {
    const { courseUrlNameOrId } = this.props.match.params;
    this.props.firebase
      .courseObjectByUrlNameOrId(courseUrlNameOrId)
      .then(course => {
        console.log("courseObjectByUrlNameOrId course = ", course);
        if (course) {
          this.setState({
            courseNotFound: false,
            courseId: course.id,
            course: course
          });
          this.loadData(course.id)
        } else {
          this.setState({
            courseNotFound: true
          });
        }
      })
      .catch(error => {
        console.log("courseObjectByUrlNameOrId error = ", error);
      });
  }

  loadData = (courseId) => {
    const { authUser } = this.props;

    console.log(" => ", authUser);

    this.props.firebase
      .userProgress(authUser.uid)
      .onSnapshot(snapshot => {
        const progress = snapshot.data() ? snapshot.data() : {}
        this.setState({ progress: progress });
        this.saveStartTimeIfNeeded()
      }, function (error) {
        console.log("userProgress error = ", error);
      });

    this.props.firebase
      .groupByUserId(authUser.uid)
      .onSnapshot(snapshot => {
        var group = snapshot.docs[0].exists ? snapshot.docs[0].data() : null
        if (group) {
          group.id = snapshot.docs[0].id
          this.props.firebase
            .groupCourseExerciseProgress(group.id, courseId)
            .onSnapshot(snapshot => {
              var groupProgress = snapshot.exists ? snapshot.data() : null
              if (groupProgress) {
                this.setState({
                  groupProgress: groupProgress
                });
              }
            }, function (error) {
              console.log("groupCourseExerciseProgress error = ", error);
            })


          this.setState({
            group: group
          });
        }
      }, function (error) {
        console.log("groupByUserId error = ", error);
      })

    this.props.firebase
      .courseVideosList(courseId)
      .then(videos => {
        this.setState({ videos: videos });
      })
      .catch(error => {
        console.log("courseVideosList error = ", error);
      });


    this.props.firebase
      .courseExercisesList(courseId)
      .then(items => {
        var exercises = {}
        items.forEach(function (item) {
          exercises[item.id] = item
        })
        this.setState({ exercises: exercises });
      })
      .catch(error => {
        console.log("courseExercisesList error = ", error);
      });

    this.props.firebase
      .coursePagePreItem(courseId)
      .then(item => {
        console.log("coursePagePreItem success = ", item);
        this.setState({
          prePageLoaded: true,
          prePage: item
        });
      })
      .catch(error => {
        console.log("coursePagePreItem error = ", error);
        this.setState({
          prePageLoaded: true,
        });
      });


    this.props.firebase
      .user(authUser.uid)
      .get()
      .then(snapshot => {
        const user = snapshot.exists ? snapshot.data() : {}
        if (user) {
          this.setState({ platformInfoDone: user.platformInfoDone });
        }

      }, function (error) {
        console.log("error = ", error);
      });

  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  handleQuestionSelected(option) {
    const { selectedVideoId } = this.state;
    const { videos } = this.state;
    if (option && option.next && option.next.video) {
      if (selectedVideoId) {
        this.writeVideoDone(selectedVideoId)
      } else if (videos) {
        this.writeVideoDone(videos[0].id)
      }
      this.setState({
        showQuestion: false,
        showExplanation: true
      });
    } else if (option && option.next && option.next.done) {
      this.handleVideoDone(selectedVideoId)
    } else {
      this.setState({ showQuestion: false });
    }

  }

  handleExplanationDone(video) {
    console.log("handleExplanationDone = ", video);
    if (video) {
      this.goToNextVideo(video.id)
    }

  }

  handlePlatformInfoDone() {
    const { authUser } = this.props;
    console.log("handlePlatformInfoDone = ");
    this.setState({ platformInfoDone: true });
    this.props.firebase.user(authUser.uid).set(
      { platformInfoDone: true },
      { merge: true },
    );
  }

  handlePrePageDone() {
    console.log("handlePrePageDone = ");
    this.setState({ prePage: null });
  }

  handleVideoPlatformSelected(platform) {
    const { courseId } = this.state;
    const { authUser } = this.props;
    const { progress } = this.state;

    this.createProgressIfNeeded(progress)
    progress[courseId].videoPlatform = platform


    this.props.firebase.progressVideoPlatformSelected(authUser.uid, courseId, platform)
      .then(resp => {
        console.log("progressVideoPlatformSelected success", resp);
        this.setState({
          videoPlatformDone: true,
          progress: progress
        });
      })
      .catch(error => {
        console.log("progressVideoPlatformSelected error", error);
      });

  }

  createProgressIfNeeded(progress) {
    const { courseId } = this.state;
    if (!progress) {
      progress = {}
    }

    if (!progress[courseId]) {
      progress[courseId] = {}
    }
  }

  goToNextVideo(id) {
    if (this.isLastVideo(id)) {
      this.goToExercise()
    } else {
      this.goToVideo(this.videoIndexForId(id) + 1)
    }
  }

  isLastVideo(id) {
    const { videos } = this.state;

    var index = this.videoIndexForId(id)
    console.log("isLastVideo = ", index);
    var nextIndex = index + 1

    return videos.length <= nextIndex
  }

  handleVideoDone(id) {
    const { authUser } = this.props;
    const { courseId } = this.state;
    const { groupProgress } = this.state;
    const { group } = this.state;

    if (this.isLastVideo(id)) {
      if (!groupProgress && group) {
        this.props.firebase
          .groupCourseExerciseProgress(group.id, courseId)
          .set(
            { firstUserId: authUser.uid },
            { merge: true },
          )

          .catch(error => {
            console.log("error = ", error);
          });
      }
    }

    this.writeVideoDone(id)
    this.setState({ showExplanation: true });

  }


  writeVideoDone(id) {
    const { authUser } = this.props;
    const { courseId } = this.state;
    const { progress } = this.state;


    if (this.isVideoDone(this.videoIndexForId(id))) {
      return null
    }

    this.createProgressIfNeeded(progress)
    if (!progress[courseId].videosDone) {
      progress[courseId].videosDone = []
    }
    progress[courseId].videosDone.push(id)

    console.log("writeVideoDone = ", id);

    this.props.firebase.setProgressVideoDone(authUser.uid, courseId, id)
      .then(resp => {
        this.setState({ progress: progress });
        this.props.firebase.logEvent("video_done", {
          videoId: id,
          courseId: courseId,
        })
      })
      .catch(error => {
        console.log("setProgressVideoDone error = ", error);

      });
  }

  videoIndexForId(id) {
    const { videos } = this.state;
    if (videos) {
      var idx = videos.map(function (o) { return o.id; }).indexOf(id);
      return idx
    }
    return 0
  }

  goToVideoStepper = index => () => {
    this.goToVideo(index)
  };

  goToVideo(index) {
    this.setState({
      showExplanation: false,
      showQuestion: true
    });

    console.log("goToVideo = ", index);
    this.props.history.push(ROUTES.COURSE + "/" + this.urlNameOrCourseId() + "/" + index)
  }


  goToExerciseTap = index => () => {
    this.goToExercise()
  }

  urlNameOrCourseId() {
    const { course } = this.state;
    return course.urlName ? course.urlName : course.id
  }

  goToExercise() {
    console.log("goToExercise");
    this.setState({
      selectedVideoId: undefined,
      showExplanation: false,
      showQuestion: true
    });
    this.props.history.push(ROUTES.COURSE + "/" + this.urlNameOrCourseId())
  }

  videosDoneItem(courseId) {
    const { progress } = this.state;
    if (process && progress[courseId] && progress[courseId].videosDone) {
      return progress[courseId].videosDone
    }
    return null
  }

  isVideoDone(index) {
    const { videos } = this.state;
    const { courseId } = this.state;

    if (videos && this.isIndexOk(index) && videos[index]) {
      var id = videos[index].id
      if (this.videosDoneItem(courseId) && this.videosDoneItem(courseId).includes(id)) {
        return true
      }
    }
    return false
  }

  allVideosDone() {
    const { videos } = this.state;
    const { courseId } = this.state;

    return this.videosDoneItem(courseId) && this.videosDoneItem(courseId).length == videos.length
  }

  isIndexOk(index) {
    const { videos } = this.state;
    return videos.length > index
  }

  isVideoUnlocked(index) {
    return this.isIndexOk(index) && (this.isVideoDone(index) || index == 0 || this.isVideoDone(index - 1))
  }

  hasMultiplePlatforms(videos) {
    console.log("hasMultiplePlatforms", videos);
    if (videos != null) {
      var video = videos[0]
      if (video) {
        console.log("hasMultiplePlatforms = ", video);
        return video.media.length > 1
      }
    }
    return false
  }

  saveStartTimeIfNeeded() {
    const { authUser } = this.props;
    const { progress } = this.state;
    const { courseId } = this.state;
    const { setStartTimeDone } = this.state;

    this.createProgressIfNeeded(progress)
    if(progress[courseId].startTime || setStartTimeDone){
      return
    }

    this.setState({ 
      setStartTimeDone: true
    });

    this.props.firebase.progressSubscriptionStart(authUser.uid, courseId)
      .then(resp => {
        console.log("progressSubscriptionStart", resp);
      })
      .catch(error => {
        console.log("progressSubscriptionStart", error);
      });
  }


  render() {
    const { classes } = this.props;
    const { courseId } = this.state;
    const { videoIdx } = this.props.match.params;
    const { platformUrl } = this.props.match.params;

    const { authUser } = this.props;
    const { error } = this.props;
    const { courseNotFound } = this.state;

    const { course } = this.state;
    const { progress } = this.state;
    const { groupProgress } = this.state;
    const { group } = this.state;

    const { videos } = this.state;
    const { exercises } = this.state;
    const { prePage } = this.state;
    const { prePageLoaded } = this.state;

    const { selectedVideoId } = this.state;
    const { showQuestion } = this.state;
    const { showExplanation } = this.state;
    const { platformInfoDone } = this.state;
    const { videoPlatformDone } = this.state;


    console.log("courseId = ", courseId);
    console.log("videoIdx = ", videoIdx);
    console.log("platformUrl = ", platformUrl);
    console.log("error = ", error);

    console.log("course = ", course);
    console.log("progress = ", progress);
    console.log("groupProgress = ", groupProgress);
    console.log("group = ", group);

    console.log("videos = ", videos);
    console.log("exercises = ", exercises);
    console.log("selectedVideoId = ", selectedVideoId);
    console.log("showQuestion = ", showQuestion);
    console.log("showExplanation = ", showExplanation);

    if (courseNotFound || (course && (!course.active || moment(course.startDate.toDate()).isAfter(Date())))) {
      return <div className={classes.root} >
        <div className={classes.toolbar} />

        <UserInfo />

        <Grid container className={classes.grid} spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="body2">This course is currently not available</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button variant="contained" color="primary" href={ROUTES.DASHBOARD}>
              Back to the dashboard
              </Button>
          </Grid>
        </Grid>
      </div>
    }

    if (!(course && progress && videos && group && courseId && prePageLoaded)) {
      return <div className={classes.root} >
        <div className={classes.toolbar} />

        <UserInfo />

        <Grid container className={classes.grid}>
          <CircularProgress className={classes.loading} />
        </Grid>
      </div>
    }


    var needsPlatformSelect = this.hasMultiplePlatforms(videos) && (progress && progress[courseId] ? progress[courseId].videoPlatform == null : true) && !videoPlatformDone
    var showPrePage = !this.isVideoDone(0) && prePage

    if (videoIdx) {
      if (!isNaN(videoIdx)) {
        if (this.isVideoUnlocked(videoIdx)) {
          var id = videos[videoIdx].id
          if (selectedVideoId != id) {
            this.setState({
              selectedVideoId: id,
            })
            return null
          }
        }
      }
    }



    if (this.allVideosDone() && !selectedVideoId) {
      if (exercises) {
        if (groupProgress && groupProgress.firstUserId == authUser.uid) {
          var exercise = exercises["first"]
        } else {
          var exercise = exercises["other"]
        }
        if (!exercise) {
          exercise = exercises["single"]
        }
        var currStep = videos.length
      } else {
        var exercise = {}
        var currStep = videos.length
      }


    } else {

      if (videos && selectedVideoId) {
        var video = videos.find(function (item) {
          return item.id == selectedVideoId;
        });
      } else if (this.videosDoneItem(courseId) && this.videosDoneItem(courseId).length != 0) {
        var size = this.videosDoneItem(courseId).length
        var video = videos[size]
        this.setState({
          selectedVideoId: video.id,
        })
      } else {
        var video = videos[0]
        this.setState({
          selectedVideoId: video.id,
        })
      }

      if (!video) return null

      var videoId = video.id
      var currIdx = this.videoIndexForId(videoId)
      var currStep = currIdx
      var done = this.isVideoDone(currIdx)
      if (!done && showQuestion && video.question) {
        if (!video.isShuffled) {
          this.shuffle(video.question.options)
          video.isShuffled = true
        }

        var question = video.question

        video = undefined
      }
    }


    console.log("video = ", video);
    console.log("question = ", question);


    return (
      <div className={classes.root} >
        <div className={classes.toolbar} />

        <UserInfo />

        <Grid container className={classes.grid}>

          {showPrePage &&
            <PrePage
              prePage={prePage}
              handlePrePageDone={this.handlePrePageDone}
            />
          }

          {!showPrePage && !platformInfoDone &&
            <PlatformInfo
              handlePlatformInfoDone={this.handlePlatformInfoDone}
            />}

          {!showPrePage && platformInfoDone && needsPlatformSelect &&
            <PlatformSelect
              course={course}
              userId={authUser.uid}
              handleVideoPlatformSelected={this.handleVideoPlatformSelected}
            />}

          {!showPrePage && platformInfoDone && !needsPlatformSelect && <Grid container xs={12} sm={12} md={10} lg={8} spacing={1}>


            <Grid item xs={12} sm={12} md={12}>
              <div>
                <Stepper alternativeLabel nonLinear activeStep={currStep} className={classes.stepper}>

                  {videos && videos.map((video, index) => {
                    const props = {};
                    const buttonProps = {};

                    buttonProps.onClick = this.goToVideoStepper(index)

                    var done = this.isVideoDone(index)
                    var unblocked = this.isVideoUnlocked(index)
                    buttonProps.disabled = !unblocked
                    buttonProps.completed = done

                    return (
                      <Step key={video.id} >
                        <StepButton {...buttonProps} >
                            <Hidden xsDown implementation="css">
                              {video.title}
                            </Hidden>
                        </StepButton>
                      </Step>
                    );
                  })}


                  <Step key="exercise" disabled={!this.allVideosDone()} >
                    <StepButton onClick={this.goToExerciseTap()} >
                        <Hidden xsDown implementation="css">
                          Challenge
                        </Hidden>

                    </StepButton>
                  </Step>



                </Stepper>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              {question &&
                <Question
                  question={question}
                  onQuestionSelected={this.handleQuestionSelected} />
              }


              {video && !showExplanation &&
                <Video
                  video={video}
                  progress={progress}
                  platformUrl={platformUrl}
                  defaultPlatform={course.defaultPlatform}
                  currIdx={currIdx}
                  courseId={courseId}
                  courseUrlName={this.urlNameOrCourseId()}
                  done={done}
                  onVideoDone={this.handleVideoDone} />
              }


              {video && showExplanation &&
                <Explanation
                  video={video}
                  progress={progress}
                  platformUrl={platformUrl}
                  defaultPlatform={course.defaultPlatform}
                  courseId={courseId}
                  courseUrlName={this.urlNameOrCourseId()}
                  onExplanationContinue={this.handleExplanationDone} />
              }

              {exercise && groupProgress && group && courseId &&
                <Exercise
                  exercise={exercise}
                  progress={progress}
                  groupProgress={groupProgress}
                  group={group}
                  courseId={courseId}
                />
              }

            </Grid>


          </Grid>}
        </Grid>
      </div>

    );
  }
}



const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withBrowserDetection,
  withAuthorization(condition),
  withStyles(styles)
)(CoursePage);
