import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';

import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';

import { CssBaseline, Grid, Paper, withStyles, Drawer, List, ListItemIcon, ListItemText, Divider, ListItem, Typography, Button, ListItemSecondaryAction, TextField, FormControl, FormHelperText, MenuItem, CircularProgress } from '@material-ui/core';
import withBrowserDetection from '../Session/withBrowserDetection';
import AdminTabs from './AdminTabs';
import CourseDetailItem from './CourseDetailItem';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    margin: `${theme.spacing(2)}px 0px`,
    padding: `${theme.spacing(2)}px`,
  },
  button: {
    marginTop: theme.spacing(1)
  },
  toolbar: theme.mixins.toolbar,
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
});

class AdminCourseDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      title: ''
    };
  }



  componentDidMount() {
    this.loadData()
  }

  componentWillUnmount() {

  }

  loadData = () => {
    const { authUser } = this.props;
    const { userId } = this.props;
    const { firebase } = this.props;
    const { courseVideos } = this.state;
    const { courseId } = this.props.match.params;

    console.log(courseId, "courseId => courseId ", courseId);

    firebase
      .courseObject(courseId)
      .then(course => {
        firebase.courseVideosList(course.id)
          .then(videos => {
            console.log("courseVideosList", videos);
            this.setState({
              videos: videos
            });
          })

        this.setState({ 
          course: course,
          title: course.title
        });

      }, function (error) {
        console.log("coursesList load", error);
      });

  }


  render() {
    const { classes } = this.props;
    const { userId } = this.state;
    const { userIds } = this.state;
    const { videos } = this.state;
    const { course } = this.state;

    console.log("userId = ", userId);
    console.log("userIds = ", userIds);
    console.log("videos = ", videos);

    return (
      <div className={classes.root} >
        <div className={classes.toolbar} />

        <AdminTabs />


        {course &&
          <CourseDetailItem
            course={course} 
            />
        }


          <Grid container className={classes.grid} xs={12} sm={12} md={12} spacing={3} >

            <Grid item xs={12} sm={12} md={12} lg={8} >
              {videos &&
                <List className={classes.root}>
                {videos && videos.map((video, idx) => (
                  <ListItem  button onClick={event => this.onCourseClicked(video)}>
                    <ListItemText primary={video.title} />
                    <ListItemSecondaryAction>
                    <Typography variant='caption'>test</Typography>
                  </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              }
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} >
            </Grid>


          </Grid>
      </div>

    );
  }
}



const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withBrowserDetection,
  withAuthorization(condition),
  withStyles(styles)
)(AdminCourseDetail);
