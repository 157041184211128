import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { Paper, Typography, Button, Grid, withStyles } from '@material-ui/core';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  title: {
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
    display: "block",
  },
});

// email verification disabled
const needsEmailVerification = authUser =>
  false && authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      const { classes } = this.props;
      return needsEmailVerification(this.props.authUser) ? (

        <div className={classes.root} >
          <div className={classes.toolbar} />
          <Grid container justify="center" >

            <Grid container xs={12} sm={10} md={8} lg={6} xl={6} >

              <Paper elevation={2} className={classes.paper}>

                <Typography variant="h6" component="h2" className={classes.title}>
                  Please verify your email address
                </Typography>

                <Typography variant="body2" component="h2" className={classes.title}>
                  Click on the link in the email we sent to your address.
                </Typography>


                <Button variant="contained" color="primary" className={classes.button} 
                  onClick={this.onSendEmailVerification}
                  disabled={this.state.isSent}>
                  Resend email
                </Button>

              </Paper>

            </Grid>
          </Grid>



        </div>


        // <div>
        //   {this.state.isSent ? (
        //     <p>
        //       E-Mail confirmation sent: Check you E-Mails (Spam folder
        //       included) for a confirmation E-Mail. Refresh this page
        //       once you confirmed your E-Mail.
        //     </p>
        //   ) : (
        //       <p>
        //         Verify your E-Mail: Check you E-Mails (Spam folder
        //         included) for a confirmation E-Mail or send another
        //         confirmation E-Mail.
        //     </p>
        //     )}

        //   <button
        //     type="button"
        //     onClick={this.onSendEmailVerification}
        //     disabled={this.state.isSent}
        //   >
        //     Send confirmation E-Mail
        //   </button>
        // </div>
      ) : (
          <Component {...this.props} />
        );
    }
  }

  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
  });

  return compose(
    withFirebase,
    connect(mapStateToProps),
    withStyles(styles)
  )(WithEmailVerification);
};

export default withEmailVerification;
