import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';

import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';

import { CssBaseline, Grid, Paper, withStyles, Drawer, List, ListItemIcon, ListItemText, Divider, ListItem, Typography, Button, Tabs, Tab } from '@material-ui/core';
import withBrowserDetection from '../Session/withBrowserDetection';
import DhProgressTable from '../Dashboard/DhProgressTable';
import withAdmin from '../Session/withAdmin';
import moment from 'moment';
import AdminTabs from './AdminTabs';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    margin: `${theme.spacing(2)}px 0px`,
    padding: `${theme.spacing(2)}px`,
  },
  button: {
    marginTop: theme.spacing(1)
  },
  toolbar: theme.mixins.toolbar
});

class AdminCourses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }



  componentDidMount() {
    this.loadData()
  }

  componentWillUnmount() {

  }

  loadData = () => {
    const { firebase } = this.props;
    const { courseVideos } = this.state;
    var vids = courseVideos ? courseVideos : {}


    firebase
      .coursesAdminList()
      .then(courses => {
        courses.forEach(course => {
          firebase.courseVideosList(course.id)
            .then(videos => {
              console.log("courseVideosList", videos);
              vids[course.id] = videos
              this.setState({
                courseVideos: vids
              });
            })

        })
        this.setState({ courses: courses });

      }, function (error) {
        console.log("coursesList load", error);
      });

  }

  onCourseClicked(course) {
    this.props.history.push(ROUTES.ADMIN_COURSES + "/" + course.id)
  }




  render() {
    const { classes } = this.props;
    const { authUser } = this.props;
    const { courses } = this.state;

    console.log("authUser = ", authUser);
    console.log("courses = ", courses);

    return (
      <div className={classes.root} >
        <div className={classes.toolbar} />

          <AdminTabs />

          <Grid container className={classes.grid} xs={12} sm={12} md={12} spacing={3} >

            <Grid item xs={12} sm={12} md={12} lg={8} >
              {courses &&
                <List className={classes.root}>
                {courses && courses.map((course, idx) => (
                  <ListItem  button onClick={event => this.onCourseClicked(course)}>
                    <ListItemText primary={course.title} />
                    {/* <ListItemSecondaryAction>
                    <Typography variant='caption'>{video.sort}</Typography>
                  </ListItemSecondaryAction> */}
                  </ListItem>
                ))}
              </List>
              }
            </Grid>


          </Grid>
      </div>

    );
  }
}



const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withBrowserDetection,
  withAuthorization(condition),
  withAdmin,
  withStyles(styles)
)(AdminCourses);
