import React, { Component } from 'react';
import { compose } from 'recompose';

import { Paper, Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as ROUTES from '../../constants/routes';



const styles = theme => ({
  avatar: {
    width: 80,
    height: 80,
  },
  avatarSmall: {
    width: 60,
    height: 60,
  }
});



class AdminTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }


  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={2} className={classes.root}>
        <Tabs
          value={this.state.value}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Coursee" href={ROUTES.ADMIN_COURSES}/>
          <Tab label="Nutzer" href={ROUTES.ADMIN_USERS}/>
          <Tab label="Abrechnung" href={ROUTES.ADMIN_BILLING}/>
          <Tab label="Developer" href={ROUTES.ADMIN_DEV}/>
        </Tabs>
      </Paper>
    )
  }
}

export default compose(
  withStyles(styles)
)(AdminTabs);


