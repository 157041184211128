import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import { CssBaseline, Grid, Paper, withStyles, Drawer, List, ListItemIcon, ListItemText, Divider, ListItem, TextField, FormControl, Checkbox, Typography, Button, MenuItem, FormControlLabel, Link } from '@material-ui/core';
import Navigation from '../Navigation';

const styles = theme => ({
  layout: {
    width: 'auto',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar
});


class LiabilityWaiver extends Component {
  constructor(props) {
    super(props);

    this.state = {  };
  }

  render() {
    const { classes } = this.props;

    return (

      <main className={classes.layout}>
        <div className={classes.toolbar} />

        <Paper elevation={2} className={classes.paper}>
          <React.Fragment>
            <React.Fragment>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>


                  <Typography component="h1" variant="h6" >Co-instructEd LLC Liability Waiver</Typography>
                  <Typography component="h1" variant="body2">
                      <p>The information that Co-instructEd provides or may provide to you, including without limitation any information related to third-party software providers like Zoom or Skype, are for informational purposes only. Co-instructEd  expressly does not assume any liability for any of your interactions with such third-party software. Co-instructEd  accepts no liability in contract, tort or otherwise (including negligence) arising directly out of or in connection with the third-party software or your use thereof. In no event shall Co-instructEd  be liable for any loss of profits, goodwill, loss of business, loss of data or any other indirect or consequential loss or damage whatsoever. By agreeing to a demo with Co-instructEd, you agree to hold Co-instructEd  and its officers, directors, affiliates, agents, employees, successors and assigns, harmless against all claims, whether formal or informal, losses, liabilities, damages, deficiencies, costs and expenses, including reasonable attorneys’ fees and expenses of investigation and defense incurred, suffered or accrued by you, directly or indirectly, as a result of or based upon any other matter that arises out of, or relates to, conduct or acts of any kind associated with, or in any way related to, any actions beyond the direct control of Co-instructEd.
                      </p>
                  </Typography>

                </Grid>
            
              </Grid>
            </React.Fragment>

          </React.Fragment>

        </Paper>

      </main>

    );
  }
}


export default compose(
  withStyles(styles)
)(LiabilityWaiver);
