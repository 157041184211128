import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { List, Avatar, ListItem, Drawer, Hidden } from '@material-ui/core';
import { withAuthentication, withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import UserAvatar from './UserAvatar';


const drawerWidth = 200;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
});


function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}



class UserInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }



  componentDidMount() {
    const { authUser } = this.props;
    this.props.firebase
      .user(authUser.uid)
      .onSnapshot(snapshot => {
        const user = snapshot.data() ? snapshot.data() : null
        if (user) {
          this.setState({ user: user });
        }

      }, function (error) {
      });
    this.listener = this.props.firebase.onAuthUserListener(
      authUser => {
        if (!condition(authUser)) {
          this.props.history.push(ROUTES.LOGIN);
        }
      },
      () => this.props.history.push(ROUTES.LOGIN),
    );
    this.props.firebase.setDrawerListener(
      drawerOpen => {
        this.setState({ drawerOpen: drawerOpen });
      }
    );
  }

  componentWillUnmount() {
    // this.listener();
  }

  handleDrawerToggle = () => {
    const { firebase } = this.props;
    firebase.toogleDrawer()
  }


  render() {
    const { user } = this.state;
    const { loading } = this.state;

    const { classes } = this.props;
    const { authUser } = this.props;
    const { container } = this.props;
    const { firebase } = this.props;
    var { drawerOpen } = this.state;


    var currUser = user ? user : authUser


    const drawer = (
      <List  height="100%" style={{ backgroundColor: "#F4F4F4", height: "100%" }}>
        <ListItem>
          <a href={ROUTES.DASHBOARD}>
          <UserAvatar className={classes.avatar} user={currUser}></UserAvatar>
          </a>
        </ListItem>
        <ListItemLink href={ROUTES.DASHBOARD}>
          <Typography variant="subtitle1">
            Hello {currUser.name} {currUser.surname}!
          </Typography>
        </ListItemLink>
        <ListItemLink id='dashboard' href={ROUTES.DASHBOARD}>
          <Typography variant="subtitle1">
            Dashboard
          </Typography>
        </ListItemLink>
        <ListItemLink href={ROUTES.FAQ}>
          <Typography variant="subtitle1">
            FAQ
          </Typography>
        </ListItemLink>
        <ListItemLink href={ROUTES.SETTINGS}>
          <Typography variant="subtitle1">
            Settings
          </Typography>
        </ListItemLink>
      </List>
    )



    return (
      <div >

        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={drawerOpen}
            onClose={event => this.handleDrawerToggle()}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            
            {drawer}
          </Drawer>
        </Hidden>

        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            {drawer}
          </Drawer>

        </Hidden>

      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
  withStyles(styles)
)(UserInfo);
