import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import { CssBaseline, Grid, Paper, withStyles, Drawer, List, ListItemIcon, ListItemText, Divider, ListItem, TextField, FormControl, Checkbox, Typography, Button, MenuItem, FormControlLabel, Link, CircularProgress } from '@material-ui/core';
import Navigation from '../Navigation';
import withBrowserDetection from '../Session/withBrowserDetection';

const styles = theme => ({
  layout: {
    width: 'auto',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar
});


const INITIAL_STATE = {
  divisions: [],
  division: "",
  tos: false,
  error: null,
};

class LoginSso extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  componentDidMount() {
  }

  componentWillUnmount() {
  }


  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    this.createNewUser()
    event.preventDefault();
  };

  onForgotPassword = event => {
    const { linkError } = this.state;
    var query = linkError ? "?email="+linkError.email : ""
    this.props.history.push(ROUTES.PASSWORD_FORGET + query);
    event.preventDefault();
  };

  onLinkAccount = event => {
    const { firebase } = this.props;
    const { linkError } = this.state
    const { password } = this.state

    this.setState({  
      loading: true, 
      errorMsg: null 
    });

    var pendingCred = linkError.credential;
    var email = linkError.email;

    firebase.auth.signInWithEmailAndPassword(email, password)
      .then((user) => {
        // Step 4a.
        // console.log("onLinkAccount signInWithEmailAndPassword =>", user);
        try{
        firebase.setAzureToken(user.user.uid, pendingCred.accessToken)
        }catch(error){firebase.addError("onLinkAccount setAzureToken error", error)}
        return user.user.linkWithCredential(pendingCred);
      }).then(() => {
        // Google account successfully linked to the existing Firebase user.
        this.onLoginSuccessful()
      }).catch((error) => {
        console.log("onLinkAccount error =>", error);
        firebase.addError("onLinkAccount error", error)

        this.setState({ 
          loading: false,
          errorMsg: "Login mit diesem password nicht möglich. Bitte versuchen Sie es erneut." 
        });
        
      });

    event.preventDefault();
  };

  onAzureLogin = event => {
    this.setState({  
      loading: true, 
      errorMsg: null 
    });

    const { firebase } = this.props;

    var provider = new firebase.oAuthProvider('microsoft.com');

    provider.setCustomParameters({
      tenant: firebase.azureTenant
    });
    console.log("onAzureLogin provider =>", provider);

    firebase.auth.signInWithPopup(provider)
      .then((result) =>  {
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.
        // OAuth access token can also be retrieved:
        // result.credential.accessToken
        // OAuth ID token can also be retrieved:
        // result.credential.idToken
        console.log("getRedirectResult success =>", result);
        // firebase.addError("signInWithPopup success", result)
        this.setState({  loading: false });
        if(result && result.user && result.additionalUserInfo && result.additionalUserInfo.profile){

          const userData = {
            uid: result.user.uid,
            email: result.user.email,
            name: result.additionalUserInfo.profile.givenName,
            surname: result.additionalUserInfo.profile.surname,
            azureId: result.additionalUserInfo.profile.id,
            token: result.credential.accessToken,
          }

          firebase.setAzureToken(userData.uid, userData.token)

          if(result.additionalUserInfo.isNewUser){
            this.showSelectDivision(result.user.email, userData)
          }else{
            firebase.userObject(userData.uid)
              .then((user) => {
                if(user){
                  firebase.updateUser(userData.uid, userData.name, userData.surname, userData.azureId)
                  this.onLoginSuccessful()
                }else{
                  this.showSelectDivision(result.user.email, userData)
                }
              })
          }
        }else{
          throw new Error('Login with azure failed')
        }
      })
      .catch((error) => {
        console.log("getRedirectResult error =>", error);

        // An error happened.
        if (error.code === 'auth/account-exists-with-different-credential') {
          // Step 2.
          // User's email already exists.
          // The pending Google credential.
          // The provider account's email address.
          var email = error.email;
          // Get sign-in methods for this email.
          firebase.auth.fetchSignInMethodsForEmail(email).then((methods) => {
            // Step 3.
            // If the user has several sign-in methods,
            // the first method in the list will be the "recommended" method to use.
            if (methods[0] === 'password') {
              // Asks the user their password.
              // In real scenario, you should handle this asynchronously.
              this.showLinkUser(error)
            }
          });
        }else{
          firebase.addError("signInWithPopup error", error)
          this.setState({ 
            loading: false,
            errorMsg: "Der Login mit Ihrem Strabag-Account ist fehlgeschlagen. Bitte versuchen Sie es erneut." 
          });
        }



        
      });


    event.preventDefault();
  };

  createNewUser(){
    const { userData } = this.state;
    const { division } = this.state;
    const { firebase } = this.props;

    this.setState({  loading: true });

    firebase.createUser(userData.uid, userData.email, userData.name, userData.surname, division, userData.azureId)
      .then(() => {
        const { authUser } = this.props;
        userData.division = division;
        var authUserNew = {
          ...authUser,
          ...userData,
        };
        this.props.onSetAuthUser(authUserNew);
        this.onLoginSuccessful()
      }).catch((error) => {
        console.log("createNewUser error", error);
        firebase.addError("createUser error", error)
      })
  }

  showLinkUser(error){
    console.log("showLinkUser =>", error);
    this.setState({ 
      linkError: error,
      loading: false,
    });
  }

  showSelectDivision(email, userData){
    this.getDivisions(email)
    console.log("showSelectDivision =>", userData);
    this.setState({ 
      userData: userData,
      loading: false,
    });
  }

  onLoginSuccessful(){
    this.setState({ ...INITIAL_STATE });
    const {location} = this.props;
    if(location.state && location.state.from) {
        this.props.history.push(location.state.from);
    } else {
        this.props.history.push(ROUTES.DASHBOARD);
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };
  handleCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getDivisions = email => {
    var mailEnding = email.split('@')[1]
    console.log("mailEnding => ", mailEnding);
    if (mailEnding) {
      this.props.firebase
        .companyByMailEnding(mailEnding)
        .get()
        .then(function (querySnapshot) {
          var company = null
          querySnapshot.forEach(function (doc) {
            console.log(doc.id, " => ", doc.data());
            company = doc;
          })
          return company
        })
        .then(doc => {
          if (doc) {
            var company = doc.data()
            this.setState({
              companyId: doc.id,
              divisions: company.divisions.map(division => ({ label: division.name, value: division.id }))
            });
          } else {
            this.setState({
              companyId: '',
              divisions: []
            });
          }

        })
        .catch(error => {
          console.log("onEmailChange => ", error);
        });
    }
  }


  render() {
    const { classes } = this.props;
    const { state } = this.props;
    const { userData } = this.state;
    const { loading } = this.state;
    const { linkError } = this.state;
    const { errorMsg } = this.state;
    const {
      division,
      password,
      divisions,
      tos,
    } = this.state;

    const isInvalid =
      division === '' ||
      tos === false;

    const isInvalidLink = password === ''

    return (

      <main className={classes.layout}>
        <div className={classes.toolbar} />

        <Typography component="h1" variant="h4" color="textSecondary" align="center">Herzlich Willkommen auf der <br />Schulungsplattform von co-instructed!</Typography>

        <Paper elevation={2} className={classes.paper}>
          {userData ? <React.Fragment>
            <React.Fragment>

              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Typography component="h1" variant="h5" color="textSecondary" align="center" >
                    Hallo {userData.name} {userData.surname}
                  </Typography>

                </Grid>

                <Grid item xs={12}>
                  <TextField
                    disabled={divisions.length === 0}
                    required
                    id="select-division"
                    select
                    label="Bereich auswählen"
                    className={classes.textField}
                    value={division}
                    onChange={this.handleChange('division')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    fullWidth
                    variant="outlined"
                  >
                    {divisions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>


                </Grid>



                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Checkbox name="tos" value="tos" color="primary" checked={this.state.tos} onChange={this.handleCheckbox} />
                    <Typography component="body" variant="body2" color="textSecondary">
                      Ich stimme den <Link target="_blank" rel="noopener" href={ROUTES.TOS}>Datenschutzbestimmungen</Link> zu
                </Typography>

                  </Grid>
                </Grid>

              </Grid>
            </React.Fragment>

            {errorMsg ? <p>{errorMsg}</p> : ""}

            <div className={classes.buttons}>

            {!loading ? <Button
                disabled={isInvalid}
                id="btn-create-user"
                variant="contained"
                color="primary"
                onClick={this.onSubmit}
                className={classes.button}
              >
                Abschließen
            </Button>
            : <CircularProgress className={classes.button} size={36} style={{ marginRight: 40 }} />}

            </div>

          </React.Fragment>
          : linkError ? <React.Fragment>

              <React.Fragment>

              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Typography component="h1" variant="h5" color="textSecondary" align="center" >
                      Willkommen zurück! Sie sind bereits bei co-instructed registriert. Bitte geben Sie Ihr co-instructed password ein, welches Sie bei der Registrierung ausgewählt haben.
                  </Typography>

                </Grid>

                <Grid item xs={12}>
                <TextField
                    required
                    id="password"
                    name="password"
                    value={password}
                    label="Ihr co-instructed password"
                    onChange={this.onChange}
                    fullWidth
                    autoComplete="password"
                    variant="outlined"
                    type="password"
                  />

                </Grid>
                </Grid>

            {errorMsg ? <p>{errorMsg}</p> : ""}

            <div className={classes.buttons}>

            {!loading ? <Button
                disabled={isInvalidLink}
                id="btn-link-account"
                variant="contained"
                color="primary"
                onClick={this.onLinkAccount}
                className={classes.button}
              >
                Anmelden
            </Button>
            : <CircularProgress className={classes.button} size={36} style={{ marginRight: 40 }} />}

            </div>
            <div className={classes.buttons}>
            <Button 
                id="btn-forgot-password"
                variant="outline"
                color="primary"
                onClick={this.onForgotPassword}
                className={classes.button}
              >
                Forgot password
            </Button>
            </div>

            

            </React.Fragment>

          </React.Fragment>
          :
          <React.Fragment>
            <React.Fragment>

              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Typography component="h1" variant="h5" color="textSecondary" align="center" >
                    Bitte melden Sie sich hier mit Ihrem <br/>Strabag sbsit Account an.
                  </Typography>

                </Grid>

                {errorMsg && <Typography component="body" variant="body" color="textSecondary">
                    {errorMsg}
                  </Typography>}

              </Grid>
            </React.Fragment>

            <div className={classes.buttons}>

            {!loading ? <Button
                id="btn-signon-azure"
                variant="contained"
                color="primary"
                onClick={this.onAzureLogin}
                className={classes.button}
              >
                Jetzt anmelden
            </Button> 
            : <CircularProgress className={classes.button} size={36} style={{ marginRight: 40 }} />}

            </div>


          </React.Fragment>
          }

        </Paper>

      </main>

    );
  }
}

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser =>
    dispatch({ type: 'AUTH_USER_SET', authUser }),
});

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/user-not-found';

export default compose(
  withFirebase,
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps,
  ),
  withBrowserDetection
)(LoginSso);
