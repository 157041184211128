import { createMuiTheme, CssBaseline, MuiThemeProvider, withStyles } from '@material-ui/core';
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { compose } from 'redux';
import * as ROUTES from '../../constants/routes';
import AdminBilling from '../Admin/AdminBilling';
import AdminCourseDetail from '../Admin/AdminCourseDetail';
import AdminCourses from '../Admin/AdminCourses';
import AdminDev from '../Admin/AdminDev';
import AdminPage from '../Admin/AdminPage';
import AdminUsers from '../Admin/AdminUsers';
import Course from '../Course';
import Dashboard from '../Dashboard';
import Faq from '../Faq';
import LoginEmail from '../Login';
import LoginSSO from '../LoginSSO';
import Navigation from '../Navigation';
import PasswordForget from '../PasswordForget';
import Register from '../Register';
import { withAuthentication } from '../Session';
import Settings from '../Settings';
import Start from '../Start';
import Tos from '../Tos';
import LiabilityWaiver from '../Liability';
import colors from './constants';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.secondaryColor,
    },
    secondary: {
      main: colors.primaryColor,
    },
    background: {
      main: colors.greyColor,
    }
  },
});

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar
});



const App = () => (
    <MuiThemeProvider theme={theme}>
      <Router>
        <div className={styles.root}>
          <CssBaseline />
          <Navigation />


            <Route exact path={ROUTES.LANDING} component={Start} />
            <Route path={ROUTES.DASHBOARD} component={Dashboard} />
            <Route path={ROUTES.REGISTER} component={Register} />
            <Route path={ROUTES.LOGIN} component={LoginEmail} />
            <Route path={ROUTES.LOGIN_EMAIL} component={LoginEmail} />
            <Route path={ROUTES.FAQ} component={Faq} />
            <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForget} />
            <Route path={ROUTES.TOS} component={Tos} />
            <Route path={ROUTES.LIABILITY_WAIVER} component={LiabilityWaiver} />
            <Route path={ROUTES.SETTINGS} component={Settings} />

            <Route path ={`${ROUTES.COURSE}/:courseUrlNameOrId/:videoIdx?/:platformUrl?`} component={Course} />

            <Route path ={`${ROUTES.ADMIN_COURSES}/:courseId`} component={AdminCourseDetail} />
            <Route exact path={ROUTES.ADMIN_COURSES} component={AdminCourses} />
            <Route path={ROUTES.ADMIN_USERS} component={AdminUsers} />
            <Route path={ROUTES.ADMIN_BILLING} component={AdminBilling} />
            <Route path={ROUTES.ADMIN_DEV} component={AdminDev} />
            <Route exact path={ROUTES.ADMIN} component={AdminPage} />

            {/* <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route path={ROUTES.HOME} component={HomePage} />
            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
            <Route path={ROUTES.ADMIN} component={AdminPage} /> */}

        </div>
      </Router>
    </MuiThemeProvider>
);

export default compose(
  withAuthentication,
  withStyles(styles)
)(App);
