import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';

import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';

import { CssBaseline, Grid, Paper, withStyles, Drawer, List, ListItemIcon, ListItemText, Divider, ListItem, Typography, Button, Tabs, Tab } from '@material-ui/core';
import withBrowserDetection from '../Session/withBrowserDetection';
import DhProgressTable from '../Dashboard/DhProgressTable';
import withAdmin from '../Session/withAdmin';
import moment from 'moment';
import AdminTabs from './AdminTabs';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    margin: `${theme.spacing(2)}px 0px`,
    padding: `${theme.spacing(2)}px`,
  },
  button: {
    marginTop: theme.spacing(1)
  },
  toolbar: theme.mixins.toolbar
});

class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }



  componentDidMount() {
  }

  componentWillUnmount() {

  }



  render() {
    const { classes } = this.props;
    const { authUser } = this.props;
    const { userId } = this.state;
    const { userIds } = this.state;
    const { courses } = this.state;

    console.log("authUser = ", authUser);
    console.log("userId = ", userId);
    console.log("userIds = ", userIds);
    console.log("courses = ", courses);

    return (
      <div className={classes.root} >
        <div className={classes.toolbar} />

          <AdminTabs />

      </div>

    );
  }
}



const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withBrowserDetection,
  withAuthorization(condition),
  withAdmin,
  withStyles(styles)
)(AdminPage);
