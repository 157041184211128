import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Toolbar from '@material-ui/core/Toolbar';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LensIcon from '@material-ui/icons/Lens';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DescriptionIcon from '@material-ui/icons/Description';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import { red, blueGrey, green } from '@material-ui/core/colors';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Link, Chip, Avatar, CircularProgress, Grid, Modal, Popover, Snackbar, SnackbarContent, ExpansionPanelSummary, ExpansionPanelDetails, Divider, ExpansionPanelActions, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Hidden } from '@material-ui/core';
import { withAuthorization } from '../Session';
import UserAvatar from '../UserInfo/UserAvatar';
import accentColor from '../App';
import secondaryColor from '../App';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import  VpnKeyIcon  from '@material-ui/icons/VpnKey';
import colors from '../App/constants';

const styles = theme => ({
  table: {

  },
  head: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  progress: {
    margin: 1,
    fontSize: 14,
  },
  progressOther: {
    margin: 0,
    fontSize: 10
  },
  iconCheckbox: {
    margin: 2,
    fontSize: 24
  },
  iconCheckboxOther: {
    margin: 2,
    fontSize: 18
  },
  paper: {

  },
  chip: {
    margin: `${theme.spacing(1)}px 0`,
  },
  snackbar: {
    backgroundColor: theme.palette.secondary.main
  },
  snackbarMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  snackbarIcon: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  loading: {
    margin: theme.spacing(6),
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    color: '#fff',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
    display: 'flex'
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
    padding: '0px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px',
    },
  },
  column: {
    display: 'flex',
    alignItems: 'center',
  },
  column50: {
    flexBasis: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  columnEnd: {
    flexBasis: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: 'auto',
  },
})(MuiExpansionPanel);


class DhProgressPanel extends Component {
  constructor(props) {
    super(props);
    
    
    this.state = {
      loading: true,
    }

    if (props.userId) {
      this.loadData()
    }
    
  }



  componentDidMount() {

  }

  componentWillUnmount() {

  }


  loadData = () => {
    const { authUser } = this.props;
    const { userId } = this.props;
    const { firebase } = this.props;
    const { courseVideos } = this.state;
    var vids = courseVideos ? courseVideos : {}

    console.log("userId ", userId);


    this.props.firebase
      .user(userId)
      .onSnapshot(snapshot => {
        const user = snapshot.data() ? snapshot.data() : null
        if (user) {
          this.setState({
            user: user
          });
        }

      }, function (error) {
        console.log("error = ", error);
      });


    this.props.firebase
      .userProgress(userId)
      .onSnapshot(snapshot => {
        const progress = snapshot.data() ? snapshot.data() : {}
        this.setState({ progress: progress });
      }, function (error) {
        console.log("error = ", error);
      });

    firebase
      .coursesList(authUser.videoGroup)
      .then(courses => {
        courses.forEach(course => {
          firebase.courseVideosList(course.id)
            .then(videos => {
              console.log("courseVideosList", videos);
              vids[course.id] = videos
              this.setState({
                courseVideos: vids
              });
            })

        })
        this.setState({ courses: courses });

      }, function (error) {
        console.log("coursesList load", error);
      });

  }


  urlNameOrCourseId(course){
    return course.urlName ? course.urlName : course.id
  }

  onCourseClicked(course) {
    if (course.isActive) {
      this.props.history.push(ROUTES.COURSE + "/" + this.urlNameOrCourseId(course))
    }
  }
  onVideoClicked(course, idx, done) {
    if (course.isActive && done) {
      this.props.history.push(ROUTES.COURSE + "/" + this.urlNameOrCourseId(course) + "/" + idx)
    }
  }

  onEndSubscriptionClicked() {
    this.closeEndSubscription()
    const { userId } = this.props;
    const { firebase } = this.props;
    const { localSubs } = this.state;
    const { actionCourseId } = this.state;
    var courseId = actionCourseId
    var subs = localSubs ? localSubs : {}
    subs[courseId] = false
    firebase.progressSubscriptionEnd(userId, courseId)
      .then(resp => {
        console.log("progressSubscriptionEnd", resp);
        this.setState({
          localSubs: subs
        });
      })
      .catch(error => {
        console.log("progressSubscriptionEnd", error);
      });
  }
  onRestartSubscriptionClicked(courseId) {
    const { userId } = this.props;
    const { firebase } = this.props;
    const { localSubs } = this.state;
    var subs = localSubs ? localSubs : {}
    subs[courseId] = true
    firebase.progressSubscriptionRestart(userId, courseId)
      .then(resp => {
        console.log("progressSubscriptionRestart", resp);
        this.setState({
          localSubs: subs
        });
      })
      .catch(error => {
        console.log("progressSubscriptionRestart", error);
      });
  }

  progressIcon(done, idx, course) {
    const { classes } = this.props;
    const { isOther } = this.props;
    if (isOther) {
      return <LensIcon
        className={classes.progressOther}
        color={done ? 'primary' : 'disabled'}
        />
    }
    return <LensIcon
        className={classes.progress}
        color={done ? 'primary' : 'disabled'}
        />
  }



  onReminderClicked() {
    const { userId } = this.props;
    const { user } = this.state;
    const { currUserId } = this.props;

    this.props.firebase.reminder().add({
      userUid: userId,
      email: user.email,
      name: user.name,
      surname: user.surname,
      creatorUid: currUserId,
      createdAt: this.props.firebase.fieldValue.serverTimestamp()
    })
      .then(() => {
        this.setState({
          reminderSuccess: true
        });
      })
  }

  handleOpen = event => {
    this.setState({
      anchorEl: event.currentTarget,
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleReminderClose = () => {
    this.setState({ reminderSuccess: false });
  };

  openEndSubscription = (courseId) => {
    this.setState({
      showEndSubscription: true,
      actionCourseId: courseId
    });
  };
  closeEndSubscription = () => {
    this.setState({ showEndSubscription: false });
  };

  handleExpandedChange = idx => (event, expanded) => {
    this.setState({
      expandedIdx: expanded ? idx : -1,
    });
  };

  handleOpen = event => {
    this.setState({
      anchorEl: event.currentTarget,
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onStartNextVideo = event => {
    const { courses } = this.state;

    var nextCourse = null;
    courses.forEach((course, idx) => {
      var doneVideos = this.videosDoneItem(course.id);
      if (nextCourse == null && (doneVideos == null || doneVideos.length != course.videoCount)) {
        nextCourse = course;
      }
    })

    if(nextCourse){
      this.onCourseClicked(nextCourse)
    }else{
      this.handleOpen(event)
    }
  }

  allVideosDone(course){
    var videosDone = this.videosDoneItem(course.id)
    return videosDone && videosDone.length == course.videoCount
  }

  videosDoneItem(courseId){
    const { progress } = this.state;
    if (process && progress[courseId] && progress[courseId].videosDone) {
      return progress[courseId].videosDone
    }
    return null
  }
  exerciseDone(courseId){
    const { progress } = this.state;
    return progress && progress[courseId] && progress[courseId].exerciseDone
  }

  render() {
    const { classes } = this.props;
    const { isOther } = this.props;
    const { expandFirstItem } = this.props;

    const { user } = this.state;
    const { courses } = this.state;
    const { progress } = this.state;
    const { reminderSuccess } = this.state;
    const { courseVideos } = this.state;
    const { localSubs } = this.state;
    const { expandedIdx } = this.state;

    console.log("table => progress ", progress);

    var expandedIdx2 = expandFirstItem ? 0 : expandedIdx

    if (courses && progress) {


      var courseActive = true

      courses.map((course, idx) => {
        course.isActive = true //courseActive
        course.subscribed = localSubs && localSubs[course.id] !== undefined ? localSubs[course.id] : user.subscriptions && user.subscriptions[course.id] !== undefined ? user.subscriptions[course.id] : true

        var videoState = []
        var videosSet = 0
        var progressCount = 0
        if (this.videosDoneItem(course.id)) {
          progressCount = this.videosDoneItem(course.id).length
        }
        for (var i = 0; i < course.videoCount; i++) {
          if (videosSet < progressCount) {
            videoState.push(true)
            videosSet++
          } else {
            videoState.push(false)
            if (course.subscribed) {
              courseActive = false
            }
          }
        }

        course.videoState = videoState


        course.videoStateUi = videoState.map((done, idx) =>
          this.progressIcon(done, idx, course)
        );

        if (this.exerciseDone(course.id)) {
          course.exerciseStateUi = <CheckBoxIcon className={isOther ? classes.iconCheckboxOther : classes.iconCheckbox} color="primary" />
        } else {
          course.exerciseStateUi = <CheckBoxOutlinedIcon className={isOther ? classes.iconCheckboxOther : classes.iconCheckbox} color="accent" />
        }


        if (isOther) {
          course.courseName = <div>{course.title}</div>
        } else {
          course.courseName = <div>Course {idx + 1}: {course.title}</div>
        }


      })

    }

    console.log("courses => ", courses);
    console.log("progress => ", progress);
    console.log("user => ", user);


    if (!(user && progress && courses)) {
      return (
        <Paper elevation={2} className={classes.paper} align="center">
          <CircularProgress className={classes.loading} />
        </Paper>
      )
    }

    var fullName = user.name + " " + user.surname
    const length = 30
    var fullName = fullName.length > length ? fullName.substring(0, length - 3) + "..." : fullName;
    return (
      <div>

        <Paper elevation={2} className={classes.paper}>

          <div className={classes.head} >
            <div className={classes.column50}>
              <Typography className={classes.heading}>Course</Typography>
            </div>
            <div className={classes.column50}>
              <Typography className={classes.heading}>Progress</Typography>
            </div>
            <div className={classes.column} align="right">
              <Typography className={classes.heading}>Challenge</Typography>
            </div>
          </div>

          {courses && courses.map((c, idx) => (
            <div>
              {!c.subscribed &&
                <ExpansionPanel defaultExpanded={false} margin='auto' expanded={false} square={true} >
                  <ExpansionPanelSummary>
                    <div className={classes.column50}>
                      <Typography className={classes.secondaryHeading}>{c.courseName}</Typography>
                    </div>
                    <div className={classes.columnEnd} >
                      <Button size="small" color="primary" onClick={event => this.onRestartSubscriptionClicked(c.id)}>
                        Start course
                    </Button>
                    </div>
                  </ExpansionPanelSummary>
                </ExpansionPanel>
              }
              {c.subscribed && <ExpansionPanel 
                key={idx} 
                margin='auto' square={true} 
                id={idx == 0 && "step1"} 
                expanded={expandedIdx2 === idx}
                onChange={this.handleExpandedChange(idx)}
                >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                  <div className={classes.column50}>
                    <Typography className={classes.secondaryHeading} color={c.isActive ? 'primary' : 'default'} >{c.courseName}</Typography>
                  </div>
                  <div className={classes.column50}>
                    {c.videoStateUi}
                  </div>
                  <div className={classes.column} align="right">
                    {c.exerciseStateUi}
                  </div>
                </ExpansionPanelSummary>

                <Divider />

                <ExpansionPanelDetails className={classes.details}>

                  <List className={classes.root}>
                    {courseVideos && courseVideos[c.id] && courseVideos[c.id].map((video, idx) => (
                      <ListItem >
                        <ListItemIcon>
                          {c.videoState[idx] ? <LockOpenIcon /> : <LockIcon />}
                        </ListItemIcon>
                        <ListItemText primary={video.title} />
                        {c.videoState[idx] && <div>
                          <Hidden smUp implementation="css">
                            <Button size="small" variant="outlined" color="primary" onClick={event => this.onVideoClicked(c, idx, c.videoState[idx])}>
                              <PlayIcon />
                            </Button>
                          </Hidden>

                          <Hidden xsDown implementation="css">
                            <Button size="small" variant="outlined" color="primary" onClick={event => this.onVideoClicked(c, idx, c.videoState[idx])}>
                              <PlayIcon className={classes.leftIcon} />
                              Watch video
                            </Button>
                          </Hidden>
                        </div>}
                      </ListItem>
                    ))}
                    <ListItem >
                        <ListItemIcon>
                          {c.videoState[c.videoState.length-1] ? <LockOpenIcon /> : <LockIcon />}
                        </ListItemIcon>
                        <ListItemText primary="Challenge" />
                        {c.videoState[c.videoState.length-1] && <div>
                          <Hidden smUp implementation="css">
                            <Button size="small" variant="outlined" color="primary" onClick={event => this.onCourseClicked(c)}>
                              <DescriptionIcon />
                            </Button>
                          </Hidden>

                          <Hidden xsDown implementation="css">
                            <Button size="small" variant="outlined" color="primary" onClick={event => this.onCourseClicked(c)}>
                              <DescriptionIcon className={classes.leftIcon} />
                              Show exercise
                            </Button>
                          </Hidden>
                        </div>}
                      </ListItem>
                    <ListItem style={{ marginTop: 8, paddingLeft: 7 }} >
                      {c.materialLink && <Button size="small" variant="outlined" color="primary" target="_blank" rel="noopener" href={c.materialLink} disabled={!this.allVideosDone(c)} >
                        <DownloadIcon className={classes.leftIcon} />
                        Download course materials
                      </Button>}
                    </ListItem>
                  </List>
                </ExpansionPanelDetails>

                <Divider />

                <ExpansionPanelActions>
                  <Button size="small" variant="caption" color="secondary" onClick={event => this.openEndSubscription(c.id)}>
                    Delete course
                  </Button>
                  <Button size="small" variant="contained" color="primary" onClick={event => this.onCourseClicked(c)}>
                    Continue course
                  </Button>
                </ExpansionPanelActions>
              </ExpansionPanel>
              }
            </div>
          ))}

        </Paper>




        <Dialog
          open={this.state.showEndSubscription}
          onClose={this.closeEndSubscription}
          maxWidth="xs"
          aria-labelledby="dialog-end-subscription"
        >
          <DialogTitle id="dialog-end-subscription">Delete Course?</DialogTitle>
          <DialogContent>
            <Typography style={{ margin: 16 }} className={classes.typography}>
            Are you sure that you want to delete this course? Your current progress will be deleted and can't be restored. Furthermore, you will lose access to all videos and course materials of this course immediately. Are you sure that you want to delete this course and your progress?
          </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={event => this.closeEndSubscription()}
              color="default">
              Cancel
          </Button>
            <Button
              onClick={event => this.onEndSubscriptionClicked()}
              color="primary">
              Delete
          </Button>
          </DialogActions>
        </Dialog>



        <Button style={{ marginTop: 16 }} variant="contained" color="primary" onClick={this.onStartNextVideo} >
            Start next course now
        </Button>
        <Popover
            id="simple-popper"
            open={this.state.open}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography style={{ margin: 16 }} className={classes.typography}>Currently you have fulfilled all available courses (good job!) - nevertheless you can always come back and refresh your knowledge by watching the videos and the content over and over again.</Typography>
          </Popover>
        



      </div>
    );
  }
}



const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
  withStyles(styles)
)(DhProgressPanel);