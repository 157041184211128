import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { Paper, CircularProgress, withStyles } from '@material-ui/core';
import * as ROUTES from '../../constants/routes';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  loading: {
    margin: theme.spacing(6),
  },
  paper: {
    margin: theme.spacing(6),
  },
});

const withAdmin = Component => {
  class WithAdmin extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        loading: true,
      }

    }

    componentDidMount() {
      const { authUser } = this.props;
      this.props.firebase.adminObject(authUser.uid)
        .then(admin => {
          this.setState({
            admin: true,
            loading: false
          });
        })
        .catch(error => {
          console.log("adminObject", error);
          this.setState({
            admin: false,
            loading: false
          });
          this.props.history.push(ROUTES.DASHBOARD)
        });
    }

    componentWillUnmount() {
    }

    render() {
      const { classes } = this.props;
      const { loading } = this.state;

      if (loading) {
        return (
          <div className={classes.root} >
            <div className={classes.toolbar} />
            <Paper elevation={2} className={classes.paper} align="center">
              <CircularProgress className={classes.loading} />
            </Paper>
          </div>
        )
      }
      return <Component {...this.props} />;
    }
  }


  return compose(
    withFirebase,
    withStyles(styles),
  )(WithAdmin);
};

export default withAdmin;
