import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Toolbar from '@material-ui/core/Toolbar';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LensIcon from '@material-ui/icons/Lens';
import Typography from '@material-ui/core/Typography';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import { red, blueGrey, green } from '@material-ui/core/colors';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Link, Chip, Avatar, CircularProgress, Grid, Modal, Popover, Snackbar, SnackbarContent } from '@material-ui/core';
import { withAuthorization } from '../Session';
import UserAvatar from '../UserInfo/UserAvatar';

const styles = theme => ({
  table: {

  },
  head: {
    backgroundColor: theme.palette.secondary.main,
  },
  heading: {
    color: '#fff',
  },
  progress: {
    margin: 1,
    fontSize: 14
  },
  progressOther: {
    margin: 0,
    fontSize: 10
  },
  iconCheckbox: {
    margin: 2,
    fontSize: 24
  },
  iconCheckboxOther: {
    margin: 2,
    fontSize: 18
  },
  paper: {
    marginBottom: theme.spacing(1),
  },
  chip: {
    margin: `${theme.spacing(1)}px 0`,
  },
  snackbar: {
    backgroundColor: theme.palette.secondary.main
  },
  snackbarMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  snackbarIcon: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  loading: {
    margin: theme.spacing(6),
  },
});


class DhProgressTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
    if (props.userId) {
      this.loadData()
    }
  }



  componentDidMount() {

  }

  componentWillUnmount() {

  }


  loadData = () => {
    const { authUser } = this.props;
    const { userId } = this.props;

    console.log("userId ", userId);


    this.props.firebase
      .userPublic(userId)
      .onSnapshot(snapshot => {
        const user = snapshot.data() ? snapshot.data() : null
        if (user) {
          this.setState({
            user: user
          });
        }

      }, function (error) {
        console.log("error = ", error);
      });

    this.props.firebase
      .userProgress(userId)
      .onSnapshot(snapshot => {
        const progress = snapshot.data() ? snapshot.data() : {}
        this.setState({ progress: progress });
      }, function (error) {
        console.log("error = ", error);
      });

    this.props.firebase
      .coursesList(authUser.videoGroup)
      .then(courses => {
        this.setState({ courses: courses });
      }, function (error) {
        console.log("coursesList load", error);
      });

  }


  progressIcon(done, idx, course) {
    const { classes } = this.props;
    const { isOther } = this.props;
      return <LensIcon
        className={classes.progressOther}
        color={done ? 'primary' : 'disabled'} />
  }


  videosDoneItem(courseId){
    const { progress } = this.state;
    if (process && progress[courseId] && progress[courseId].videosDone) {
      return progress[courseId].videosDone
    }
    return null
  }
  

  onReminderClicked() {
    const { userId } = this.props;
    const { user } = this.state;
    const { currUserId } = this.props;

    this.props.firebase.reminder().add({
      userUid: userId,
      email: user.email,
      name: user.name,
      surname: user.surname,
      creatorUid: currUserId,
      createdAt: this.props.firebase.fieldValue.serverTimestamp()
    })
      .then(() => {
        this.setState({
          reminderSuccess: true
        });
      })
  }


  handleReminderClose = () => {
    this.setState({ reminderSuccess: false });
  };


  render() {
    const { classes } = this.props;
    const { isOther } = this.props;

    const { user } = this.state;
    const { courses } = this.state;
    const { progress } = this.state;
    const { reminderSuccess } = this.state;

    console.log("table => progress ", progress);


    if (courses && progress) {


      var courseActive = true

      courses.map((course, idx) => {
        course.isActive = courseActive
        var videoState = []
        var videosSet = 0
        var progressCount = 0
        if (this.videosDoneItem(course.id)) {
          progressCount = this.videosDoneItem(course.id).length
        }
        for (var i = 0; i < course.videoCount; i++) {
          if (videosSet < progressCount) {
            videoState.push(true)
            videosSet++
          } else {
            videoState.push(false)
            courseActive = false
          }
        }



        course.videoStateUi = videoState.map((done, idx) =>
          this.progressIcon(done, idx, course)
        );

        if (progress && progress[course.id] && progress[course.id].exerciseDone) {
          course.exerciseStateUi = <CheckBoxIcon className={isOther ? classes.iconCheckboxOther : classes.iconCheckbox} color="primary" />
        } else {
          course.exerciseStateUi = <CheckBoxOutlinedIcon className={isOther ? classes.iconCheckboxOther : classes.iconCheckbox} color="accent" />
        }


        course.courseName = <div>{course.title}</div>


      })

    }

    console.log("courses => ", courses);
    console.log("progress => ", progress);
    console.log("user => ", user);


    if (!(user && progress && courses)) {
      return (
        <Paper elevation={2} className={classes.paper} align="center">
          <CircularProgress className={classes.loading} />
        </Paper>
      )
    }

    var tableHeadVariant = "body2"
    var tableCellVariant = "caption"

    var fullName = user.name + " " + user.surname
    const length = 30
    var fullName = fullName.length > length ? fullName.substring(0, length - 3) + "..." : fullName;
    return (
      <div>

        {isOther &&
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs>
              <Chip
                avatar={<UserAvatar user={user} isSmall={true}></UserAvatar>}
                label={fullName}
                className={classes.chip}
                variant="outlined" />
            </Grid>
          </Grid>
        }


        <Paper elevation={2} className={classes.paper}>

          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell><Typography className={classes.heading} variant={tableHeadVariant}>Course</Typography></TableCell>
                <TableCell ><Typography className={classes.heading} variant={tableHeadVariant}>Progress</Typography></TableCell>
                <TableCell align="right"><Typography className={classes.heading} variant={tableHeadVariant}>Challenge</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses && courses.map((c, idx) => (
                <TableRow key={c.id}
                  hover={!isOther && c.isActive}
                >

                  <TableCell size="small" component="th" scope="row" padding="default" >
                    <Typography variant={tableCellVariant}>
                      {c.courseName}
                    </Typography>
                  </TableCell>
                  <TableCell size="small">
                    {c.videoStateUi}
                  </TableCell>

                  <TableCell size="small" align="right">
                    {c.exerciseStateUi}
                  </TableCell>
                </TableRow>

              ))}
            </TableBody>
          </Table>
        </Paper>

        {/* {isOther &&
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item >
              <Typography variant="caption" color='primary' style={{ marginTop: 10 }}>{user.email}</Typography>
            </Grid>

            <Grid item >
              <Typography
                variant="caption"
                style={{ marginTop: 10 }}>
                <a href="#" style={{ color: "inherit", textDecoration: "inherit" }} onClick={(param) =>
                  this.onReminderClicked()
                }>Remind</a></Typography>
            </Grid>
          </Grid>
        } */}

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}

          open={reminderSuccess}
          autoHideDuration={6000}
          onClose={this.handleReminderClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
        >
          <SnackbarContent
            className={classes.snackbar}
            aria-describedby="client-snackbar"
            message={
              <span id="message-id" className={classes.snackbarMessage}>
                <CheckCircleIcon className={classes.snackbarIcon} />
                Successfully reminded!
              </span>
            } />
        </Snackbar>


        {!isOther &&
          <Button style={{ marginTop: 16 }} variant="contained" color="primary" onClick={this.onStartNextVideo} >
            Start next course now
        </Button>}
        {!isOther &&
          <Popover
            id="simple-popper"
            open={this.state.open}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography style={{ margin: 16 }} className={classes.typography}>Currently you have fulfilled all available courses (good job!) - nevertheless you can always come back and refresh your knowledge by watching the videos and the content over and over again.</Typography>
          </Popover>
        }


      </div>
    );
  }
}



const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
  withStyles(styles)
)(DhProgressTable);
