import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  group: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
    display: "block",
  },
  buttonLink: {
    margin: theme.spacing(2),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  }

});

class PrePage extends Component {
  constructor(props) {
    super(props);
    this.onNext = this.onNext.bind(this);

    this.state = {
      loading: true,
    };

  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  onNext() {
    this.props.handlePrePageDone();
  }

  render() {
    const { classes } = this.props;
    const { prePage } = this.props;


    return (
      <Grid container xs={12} sm={12} md={10} lg={8} spacing={1}>


        <Grid item xs={12} sm={12} md={12}>
          <div >
            <Paper elevation={2} className={classes.paper} >

              <Typography variant="h6" component="h2" className={classes.title}>
                {prePage.title}
              </Typography>

              <Typography variant="body2" component="h2" className={classes.title}>
                {prePage.text}
              </Typography>

              <Button variant="outlined" color="primary" target="_blank" href={prePage.link} className={classes.buttonLink} >
                <PictureAsPdfIcon className={classes.leftIcon} />
                {prePage.linkText}
              </Button>

            </Paper>

            <Button variant="contained" color="primary" className={classes.button} onClick={(param) =>
              this.onNext()}>
              {prePage.actionText}
            </Button>

          </div>
        </Grid>
      </Grid>

    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
  withStyles(styles)
)(PrePage);
