import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import is_js from 'is_js'

import { Paper, Typography, Grid, withStyles } from '@material-ui/core';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  paperBrowser: {
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  title: {
    padding: theme.spacing(2),
    marginBottom: "40px"
  },
  button: {
    margin: theme.spacing(2),
    display: "block",
  },
});

// email verification disabled


const withBrowserDetection = Component => {
  class WithBrowserDetection extends React.Component {
    constructor(props) {
      super(props);
    }

    browserNotSupported() {
      return is_js.ie(11)
    }

    render() {
      const { classes } = this.props;
      return this.browserNotSupported() ? (

        <div className={classes.root} >
          <div className={classes.toolbar} />
          <Grid container justify="center" >

            <Grid container xs={12} sm={10} md={10} lg={10} xl={10} >

              <Paper elevation={2} className={classes.paperBrowser}>

                <Typography variant="h6" component="h2" className={classes.title}>
                  Please use a modern browser like:
                </Typography>

                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  spacing="32"
                >
                  <Grid item >
                    <a href="https://www.google.com/chrome/"><img src="icons/browser/chrome.png" width="100" /></a>
                  </Grid>
                  <Grid item >
                    <a href="https://www.mozilla.org/firefox/"><img src="icons/browser/mozilla.png" width="100" /></a>
                  </Grid>
                  <Grid item >
                    <a href="https://www.apple.com/safari/"><img src="icons/browser/safari.png" width="100" /></a>
                  </Grid>
                  <Grid item >
                    <a href="https://www.opera.com/"><img src="icons/browser/opera.png" width="100" /></a>
                  </Grid>
                  <Grid item >
                    <a href="https://www.microsoft.com/windows/microsoft-edge"><img src="icons/browser/edge.png" width="100" /></a>
                  </Grid>

                </Grid>

              </Paper>

            </Grid>
          </Grid>

        </div>

      ) : (
          <Component {...this.props} />
        );
    }
  }

  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
  });

  return compose(
    connect(mapStateToProps),
    withStyles(styles)
  )(WithBrowserDetection);
};

export default withBrowserDetection;
