import { Button, Grid, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import Plyr from 'react-plyr';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';



const styles = theme => ({
  paper: {
    padding: theme.spacing(2)
  },
  paperPlayer: {

  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },

});


class Video extends Component {
  constructor(props) {
    super(props);
    this.onClickContinue = this.onClickContinue.bind(this);
    this.videoPlatform = this.videoPlatform.bind(this);
    this.showPlatform = this.showPlatform.bind(this);

    this.state = {
      loading: true,
      isButtonDisabled: true
    };

  }

  onClickContinue() {
    const { video } = this.props;
    this.props.onVideoDone(video.id);
  }

  componentDidMount() {
    const { video } = this.props;
    const { courseId } = this.props;
    var runtime = this.videoRuntime()
    var runtime = runtime ? runtime : 60
    var runtime = runtime * .9
    setTimeout(() => {
      this.setState({ isButtonDisabled: false })
      this.props.firebase.logEvent("video_watched", {
        videoId: video.id,
        courseId: courseId,
      })
    }, runtime * 1000);
  }

  componentWillUnmount() {
  }

  videoPlatform() {
    const { progress } = this.props;
    const { platformUrl } = this.props;
    const { defaultPlatform } = this.props;
    const { courseId } = this.props;

    console.log("platformUser => ", progress);
    console.log("platformUrl => ", platformUrl);
    console.log("defaultPlatform => ", defaultPlatform);

    return platformUrl ? platformUrl : progress[courseId] && progress[courseId].videoPlatform ? progress[courseId].videoPlatform : defaultPlatform ? defaultPlatform : "ipad";
  }


  mediaData() {
    const { video } = this.props;
    var videoPlatform = this.videoPlatform()
    if (videoPlatform == "ipad") {
      return video.media.ipad
    }
    if (videoPlatform == "pc") {
      return video.media.pc
    }
    if (videoPlatform == "mobile") {
      return video.media.mobile
    }
    return video.media.ipad
  }


  youtubeId() {
    return this.mediaData().youtubeId
  }

  videoRuntime() {
    return this.mediaData().runtime
  }

  isIpad() {
    return this.videoPlatform() == "ipad"
  }

  hasIpad() {
    const { video } = this.props;
    return video.media.ipad != null
  }

  isPc() {
    return this.videoPlatform() == "pc"
  }

  hasPc() {
    const { video } = this.props;
    return video.media.pc != null
  }
  isMobile() {
    return this.videoPlatform() == "mobile"
  }

  hasMobile() {
    const { video } = this.props;
    return video.media.mobile != null
  }

  hasMultipleSources() {
    var count = 0
    if (this.hasIpad()) {
      count++;
    }
    if (this.hasPc()) {
      count++;
    }
    if (this.hasMobile()) {
      count++;
    }
    return count > 1
  }

  showPlatform(platform) {
    console.log("showPlatform");
    const { courseUrlName } = this.props;
    const { currIdx } = this.props;
    var url = ROUTES.COURSE + "/" + courseUrlName + "/" + currIdx + "/" + platform
    console.log("showPlatform", url);
    // this.setState({ videoSet: true })
    this.props.history.push(url)
    window.location.reload()
  }

  render() {
    const { classes } = this.props;
    const { video } = this.props;
    const { done } = this.props;
    const { showBottomControls } = this.props;
    const { isButtonDisabled } = this.state;
    const { videoSet } = this.state;

    var posterImage = "https://firebasestorage.googleapis.com/v0/b/co-instructed-develop.appspot.com/o/videos%2Fco-instructed-preview.png?alt=media&token=2c46cd0c-e9a7-4f4f-b641-042422a1c14e"

    var youtubeId = this.youtubeId()
    console.log("youtubeId", youtubeId);
    console.log("videoRuntime", this.videoRuntime());

    if (isButtonDisabled && videoSet) {
      if (this._child) {
        this._child.updateHtmlVideoSource(youtubeId, "video", video.title, posterImage, null)
      }
      console.log("video => ", video);
      this.setState({ videoSet: true })
    }

    return (
      <div>
        <Paper elevation={2} key="video" className={classes.paperPlayer}>

          {youtubeId &&
            <Plyr
              type="youtube"
              videoId={youtubeId}
              ref={(child) => { this._child = child; }}
              title={video.title}
              controls={
                ['play-large', 'play', 'progress', 'current-time', 'volume', 'airplay', 'fullscreen']
              }
              poster={posterImage}
            />
          }


        </Paper>


        {showBottomControls && <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={isButtonDisabled && !done}
              onClick={(param) =>
                this.onClickContinue()
              }>
              Continue
          </Button>
          </Grid>

          {this.hasMultipleSources() && <Grid item xs
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            {this.hasIpad() && <Grid item >
              {!this.isIpad() && <img src="/ic_ipad_selected_small.png" style={{ cursor: 'pointer' }} onClick={(param) => this.showPlatform("ipad")} width="50px" height="50px" />}
              {this.isIpad() && <img src="/ic_ipad_disabled_small.png" width="50px" height="50px" />}
            </Grid>}
            {this.hasPc() && <Grid item >
              {!this.isPc() && <img src="/ic_pc_selected_small.png" style={{ cursor: 'pointer' }} onClick={(param) => this.showPlatform("pc")} width="50px" height="50px" />}
              {this.isPc() && <img src="/ic_pc_disabled_small.png" width="50px" height="50px" />}
            </Grid>}
          </Grid>}
        </Grid>}


      </div>

    );

  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
  withStyles(styles)
)(Video);