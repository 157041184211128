import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const withAuthorization = condition => Component => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            this.listener = this.props.firebase.onAuthUserListener(
                authUser => {
                    if (!condition(authUser)) {
                        this.showLogin()
                    }
                },
                () => this.showLogin(),
            );
        }

        showLogin() {
            const { pathname } = this.props.location;
            const location = {
                pathname: ROUTES.LOGIN,
                state: { from: { pathname: pathname } }
            }
            this.props.history.push(location);
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return condition(this.props.authUser) ? ( <
                Component {...this.props }
                />
            ) : null;
        }
    }

    const mapStateToProps = state => ({
        authUser: state.sessionState.authUser,
    });

    return compose(
        withRouter,
        withFirebase,
        connect(mapStateToProps),
    )(WithAuthorization);
};

export default withAuthorization;