import React, { Component } from 'react';
import { compose } from 'recompose';


import { withStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';


const styles = theme => ({
  avatar: {
    width: 80,
    height: 80,
  },
  avatarSmall: {
    width: 60,
    height: 60,
  }
});



class UserAvatar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { user } = this.props;
    const { isSmall } = this.props;
    const { classes } = this.props;

    var picture = user.picture
    var name = user.name
    var surname = user.surname

    if (picture) {
      var avatar = <Avatar className={isSmall ? classes.avatarSmall : classes.avatar}
        src={picture}>
      </Avatar>
    }else if(name && surname){
      var avatar = <Avatar className={isSmall ? classes.avatarSmall : classes.avatar}>
        {name.charAt(0).toUpperCase()}{surname.charAt(0).toUpperCase()}
      </Avatar>
    }else {
      var avatar = <Avatar className={isSmall ? classes.avatarSmall : classes.avatar}></Avatar>
    }

    return (
      avatar
    )
  }
}

export default compose(
  withStyles(styles)
)(UserAvatar);


