import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Grid, ListItemIcon, TextField, Button } from '@material-ui/core';
import { withAuthorization } from '../Session';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

const styles = theme => ({
  table: {

  },
  head: {
    backgroundColor: theme.palette.secondary.main,
  },
  icon: {
    margin: 2,
    fontSize: 16
  },
  iconCheckbox: {
    margin: 2,
    fontSize: 24
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  progress: {
    margin: theme.spacing(6),
  },
});


class CourseDetailItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
    
  }



  componentDidMount() {
    const { course } = this.props;
    this.setState({ 
      title: course.title,
      selectedDate: moment(course.startDate.toDate())
    });
  }

  componentWillUnmount() {

  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  onSubmit = () => {
    const { course } = this.props;
    const { title } = this.state;
    const { selectedDate } = this.state;

    this.setState({ loading: true });

    this.props.firebase
      .course(course.id).set(
        {
          title: title,
          startDate: selectedDate.toDate()
        },
        { merge: true },
      ).then(item => {
        this.setState({ loading: null });
      })
      .catch(error => {
        this.setState({ 
          error: error,
          loading: null
        });
      });

    // event.preventDefault();

    // this.props.firebase
    //   .user(userId)
    //   .onSnapshot(snapshot => {
    //     const user = snapshot.data() ? snapshot.data() : null
    //     if (user) {
    //       this.setState({ user: user });
    //     }

    //   }, function (error) {
    //     console.log("error = ", error);
    //   });


    // this.props.firebase
    //   .userProgressObject(userId)
    //   .then(item => {
    //     const progress = item ? item : {}
    //     this.setState({ progress: progress });

    //   }, function (error) {
    //     console.log("error = ", error);
    //   });

    // this.props.firebase
    //   .coursesList(authUser.videoGroup)
    //   .then(courses => {
    //     this.setState({ courses: courses });
    //   }, function (error) {
    //     console.log("error = ", error);
    //   });

  }


  render() {
    const { classes } = this.props;
    const { course } = this.props;
    const { title } = this.state;
    const { selectedDate } = this.state;
    const { loading } = this.state;


    console.log("course => ", course);
    console.log("selectedDate => ", selectedDate);

    return (
      <div>


        <Paper elevation={2} className={classes.paper}>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField className={classes.outlined}
                    required
                    id="title"
                    name="title"
                    label="Titel"
                    value={title}
                    onChange={this.onChange}
                    fullWidth
                    autoComplete="fname"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    variant="outlined"
                    label="Startdatum"
                    value={selectedDate}
                    format="DD.MM.YYYY"
                    onChange={this.handleDateChange}
                  />
                </Grid>

                <div className={classes.buttons}>

                {loading ? null :<Button
                    id="btn-register"
                    variant="contained"
                    color="primary"
                    onClick={this.onSubmit}
                    className={classes.button}
                  >
                  Save
                </Button>}
                {!loading ? null : <CircularProgress className={classes.button} size={36} style={{ marginRight: 40 }} />}
                </div>

            </Grid>

        </Paper>
        
      </div>
    );
  }
}


const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
  withStyles(styles)
)(CourseDetailItem);
