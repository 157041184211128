import { FormControl, FormControlLabel, Radio, RadioGroup, Card, CardActionArea, CardContent, Paper, Button, Checkbox, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';



const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  group: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    display: "block",
  },

});


class PlatformSelect extends Component {
  constructor(props) {
    super(props);
    this.onClickContinue = this.onClickContinue.bind(this);

    this.state = {
      loading: true,
    };

  }

  onClickContinue(platform) {
    this.props.handleVideoPlatformSelected(platform);
  }




  componentDidMount() {
    const { authUser } = this.props;

  }

  componentWillUnmount() {

  }




  render() {
    const { classes } = this.props;
    const { course } = this.props;


    console.log("course => ", course);


    return (
      <Grid container xs={12} sm={12} md={10} lg={8} spacing={1}>


        <Grid item xs={12} sm={12} md={12}>
          <div >
            <Paper elevation={2} className={classes.paper} >

              <Typography variant="h6" component="h2" className={classes.title}>
                Welcome to course {course.title}
              </Typography>

              <Typography variant="body2" component="h2" className={classes.title}>
                I use {course.title} mainly on:
          </Typography>

              <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                spacing={5}
                style={{ marginTop: 20 }}
              >
                <Grid item xs container direction="column" alignItems="center">
                  <img src="/ic_ipad_big.png" style={{ cursor: 'pointer' }} onClick={(param) => this.onClickContinue("ipad")} />
                  <Typography variant="body2" component="h2" className={classes.title}>
                    iPad
              </Typography>
                </Grid>

                <Grid item xs container direction="column" alignItems="center">
                  <img src="/ic_pc_big.png" style={{ cursor: 'pointer' }} onClick={(param) => this.onClickContinue("pc")} />
                  <Typography variant="body2" component="h2" className={classes.title}>
                    PC
              </Typography>
                </Grid>

              </Grid>

            </Paper>

          </div>
        </Grid>
      </Grid>

    );

  }


}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
  withStyles(styles)
)(PlatformSelect);
